<template>
  <section>
    <section class="search-container">
      <!-- <a-row class="tab-text m-t-24">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
            </a-row>
            <a-row class="tab-text m-t-14">
                <a @click="typeActiveKey = item.key" :class="item.key == typeActiveKey ? 'active' : ''" v-for="item in typeMap" :key="item.key">{{item.name}}</a>
      </a-row>-->
      <a-form layout="inline" class="m-t-14">
        <a-form-item label="单位名称">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.companyId">
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="item in companyMap"
              :key="item.id"
              :value="item.id"
            >{{item.companyName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="人员类型">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.personnelType">
            <a-select-option value>全部</a-select-option>
            <a-select-option :value="1">管理人员</a-select-option>
            <a-select-option :value="2">劳务管理人员</a-select-option>
            <a-select-option :value="3">施工人员</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="劳务班组">
          <a-select class="search-select" placeholder="全部" v-model="searchInfo.teamId">
            <a-select-option value>全部</a-select-option>
            <a-select-option
              v-for="(item,key) in teamMap"
              :key="key"
              :value="item.id"
            >{{item.teamName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="标记">
          <a-select class="search-select" placeholder="请选择" v-model="searchInfo.liveBuiltBureau">
            <a-select-option value>全部</a-select-option>
            <a-select-option :value="1">已标记</a-select-option>
            <a-select-option :value="0">未标记</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="进出状态">
          <a-select
            showSearch
            option-filter-prop="children"
            class="search-select"
            placeholder="全部"
            v-model="searchInfo.inOutType"
          >
            <a-select-option value>全部</a-select-option>
            <a-select-option value="1">进入</a-select-option>
            <a-select-option value="2">离开</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="人员姓名">
          <a-input class="search-input" placeholder="请输入" v-model="searchInfo.personnelName" />
        </a-form-item>
        <a-form-item label="考勤时间">
          <a-range-picker class="search-range-date" v-model="searchInfo.endDate" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" class="m-r-8" @click="search">
            <a-icon type="search" />查询
          </a-button>
          <a-button class="m-r-8" @click="reset">
            <a-icon type="reload" />重置
          </a-button>
          <a-button class="item" @click="exprotFile('exportPersonnelLogList', {params: selForm})">
                        <a-icon type="export" />
                        导出
                    </a-button>
          <!-- <a-button class="m-r-8"><a-icon type="export" />导出</a-button> -->
        </a-form-item>
      </a-form>
    </section>

    <section class="list-table-container">
      <a-table
        :bordered="false"
        size="small"
        class="m-t-18"
        :columns="columns"
        :data-source="listMap"
        :pagination="{total,hideOnSinglePage:true,pageSize:pageSize,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}"
        @change="changePage"
      >
        <span slot="deviceType" slot-scope="text">{{text == 1 ? '门禁设备' : '考勤设备'}}</span>
        <span
          slot="personnelType"
          slot-scope="text"
        >{{text == 1 ? '管理人员' : text == 2 ? '劳务管理人员' :'施工人员'}}</span>
        <span
          slot="inOutType"
          slot-scope="text"
          :class="text == 1 ? 'list-status-in' : 'list-status-out'"
        >{{text == 1 ? '进入' : '离开'}}</span>
        <a-row slot="photoUrl" slot-scope="text">
          <previewImage :data="text" :size="40" />
        </a-row>
      </a-table>
    </section>
  </section>
</template>

<script>
const columns = [
  { title: '序号', dataIndex: 'key', align: 'center', key: 'key' },
  { title: '出入口名称', dataIndex: 'doorwayName', align: 'center', key: 'doorwayName' },
  { title: '设备类型', dataIndex: 'deviceType', align: 'center', key: 'deviceType', scopedSlots: { customRender: "deviceType" } },
  { title: '设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName' },
  { title: '单位名称', dataIndex: 'companyName', align: 'center', key: 'companyName' },
  { title: '人员类型', dataIndex: 'personnelType', align: 'center', key: 'personnelType', scopedSlots: { customRender: "personnelType" } },
  { title: '人员名称', dataIndex: 'personnelName', align: 'center', key: 'personnelName' },
  { title: '职务/工种', dataIndex: 'jobName', align: 'center', key: 'jobName' },
  { title: '刷卡时间', dataIndex: 'createTime', align: 'center', key: 'createTime' },
  { title: '状态', dataIndex: 'inOutType', align: 'center', key: 'inOutType', scopedSlots: { customRender: "inOutType" } },
  { title: '考勤照片', dataIndex: 'photoUrl', align: 'center', key: 'photoUrl', scopedSlots: { customRender: "photoUrl" } }
]
import previewImage from '@/components/previewImage'
import { exprotFile } from '@/until/unit'
export default {
  data() {
    return {
      columns,
      pageNum: 1,
      pageSize: 9,
      total: 0,
      listMap: [],
      searchInfo: {
        endDate: []
      },
      typeMap: [
        { name: '全部', key: 0 },
        { name: '设备名称1', key: 1 },
        { name: '设备名称2', key: 2 },
      ],
      typeActiveKey: 0,
      companyMap: [],
      teamMap: [],
    }
  },
  components: { previewImage },
  created() {
    this.init()

  },
  mounted() {
    this.queryPersonnelFaceCollectionLog()
  },
  computed:{
    selForm(){
      return {
        current: this.pageNum,
        size: this.pageSize,
        companyId: this.searchInfo.companyId,
        liveBuiltBureau: this.searchInfo.liveBuiltBureau,
        personnelName: this.searchInfo.personnelName,
        personnelType: this.searchInfo.personnelType,
        teamId: this.searchInfo.teamId,
        inOutType:this.searchInfo.inOutType,
        startDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : '',
        endDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
      };
    }
  },
  methods: {
    exprotFile,
    init() {
      this.queryCompanyDropDownBox()
      this.queryTeamDropDownBox()
    },
    //单位下拉
    queryCompanyDropDownBox() {
      this.$api.queryCompanyDropDownBox().then(res => {
        if (res.code === 200) {
          this.companyMap = res.data || []
        }
      })
    },
    //班组下拉
    queryTeamDropDownBox() {
      this.$api.queryTeamDropDownBox().then(res => {
        if (res.code === 200) {
          this.teamMap = res.data || []
        }
      })
    },
    //考勤列表
    queryPersonnelFaceCollectionLog() {
      let params = {
        current: this.pageNum,
        size: this.pageSize,
        companyId: this.searchInfo.companyId,
        liveBuiltBureau: this.searchInfo.liveBuiltBureau,
        personnelName: this.searchInfo.personnelName,
        personnelType: this.searchInfo.personnelType,
        teamId: this.searchInfo.teamId,
        inOutType:this.searchInfo.inOutType,
        startDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[0].format('YYYY-MM-DD') : '',
        endDate: this.searchInfo.endDate.length ? this.searchInfo.endDate[1].format('YYYY-MM-DD') : ''
      }
      this.$api.queryPersonnelFaceCollectionLog(params).then(res => {
        if (res.code === 200) {

          this.listMap = (res.data && res.data.records) || []
          this.total = (res.data && res.data.total) || 0
          this.listMap.forEach((item, ind) => {
            item.key = (this.pageSize * (this.pageNum - 1)) + ind + 1
          })
        }
      })
    },
    reset() {
      this.searchInfo = {
        endDate: []
      }
      this.pageNum = 1
      this.queryPersonnelFaceCollectionLog()
    },
    search() {
      this.pageNum = 1
      this.queryPersonnelFaceCollectionLog()
    },
    //分页
    changePage(p) {
      this.pageNum = p.current
      this.queryPersonnelFaceCollectionLog()
    }
  }
}
</script>

<style lang="scss" scoped>
.list-status-in {
  color: #52c41a;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #52c41a;
  }
}
.list-status-out {
  color: #e25745;
  &::before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 8px;
    background: #e25745;
  }
}
</style>